import React from 'react';

export const BannerProgressBar = ({ progress, step }: { progress: number, step?: number }) => {
    return (
        <div className="banner-progress-bar-wrapper">
            <div className="banner-progress-bar-steps">
                {[0, 1, 2, 3, 4].map((num) => (
                    <div
                        key={num}
                        className={`banner-progress-step ${step >= num ? 'active' : ''}`}
                    >
                        <div className="step-circle">{num !== 0 && num !== 4 ? num : ''}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
