interface IBqData {
  dataset: string;
  table: string;
  data:
  | unknown
  | {
    url: string;
    auid: string;
    brand: string;
    country: string;
  };
}

export const reportToMakeBq = async (data: IBqData) => {
  return fetch(
    'https://hook.eu1.make.celonis.com/48l7d7x8d76hhsopzc6rhgneldn2dtry',
    {
      method: 'POST',
      headers: { 'content-type': 'application/json;charset=UTF-8' },
      body: JSON.stringify(data),
    },
  );
};