import React from 'react';
import { ArrowSvg } from '../../assets/svg/ArrowSvg';

type GenderOptionProps = {
    label: string;
    emoji: string;
    onClick: () => void;
};

export const GenderOption = ({ label, emoji, onClick }: GenderOptionProps) => {
    const Icons = {
        arrow: ArrowSvg,
    };
    return (
        <div className="gender-option" onClick={onClick}>
            <span className="gender-emoji">{emoji}</span>
            <span className="gender-label">{label}</span>
            <span className="gender-arrow">
                <Icons.arrow />
            </span>
        </div>
    );
};