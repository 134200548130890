export const splitArray = <T>(arr: T[]): [T[], T[]] => {
  // Calculate the middle index. If the array length is odd, the first half will be larger.
  const middle = Math.ceil(arr.length / 2);

  // Split the array into two parts.
  const firstHalf = arr.slice(0, middle);
  const secondHalf = arr.slice(middle);

  return [firstHalf, secondHalf];
};
