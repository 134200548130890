import { Quiz } from "../../data/quizzes";


type Props = {
    handleBack: () => void;
    brandLogo: string;
    idx: number;
    quiz: Quiz;
}

export const DefaultHeader = ({ handleBack, brandLogo, idx, quiz }: Props) => {
    return <div className="quiz-header">
        <div className='quiz-header-container'>
            <div className='back-button-container'>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.82843 11.3927H20V13.3927H7.82843L13.1924 18.7566L11.7782 20.1708L4 12.3927L11.7782 4.6145L13.1924 6.02871L7.82843 11.3927Z" fill="#02122E" />
                </svg>
                <button className="back-button" onClick={() => handleBack()}>

                    Back</button>
            </div>

            <img className="logo" src={brandLogo} alt="Logo" />
            <div className="progress">
                {idx + 1}/{quiz.questions.length}
            </div>
        </div>
    </div>
};