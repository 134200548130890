import { brandConfig, BrandConfig } from '../config/brandConfig';

declare global {
    interface Window {
        dataLayer: any[];
        GoogleAnalyticsObject: string;
        ga: GAFunction;
    }
}

// Define the ga function type
interface GAFunction {
    (command: 'create', trackingId: string, cookieDomain: string): void;
    (command: 'send', hitType: string): void;
    (command: string, ...args: any[]): void;
}

export function initializeAnalytics(brand: string): void {
    const config: BrandConfig = brandConfig[brand];
    if (!config) {
        return;
    }

    // Initialize GTM
    ((w, d, s, l, i) => {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s) as HTMLScriptElement;
        const dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode?.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', config.gtmId);

    // Initialize GA
    ((i, s, o, g, r, a, m) => {
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] || function (...args) {
            (i[r].q = i[r].q || []).push(args);
        };
        i[r].l = 1 * new Date().getTime();
        a = s.createElement(o);
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode?.insertBefore(a, m);
    })(
        window,
        document,
        'script',
        'https://www.google-analytics.com/analytics.js',
        'ga'
    );

    // Use the GAFunction type for the ga function
    const ga: GAFunction = window.ga;

    ga('create', config.gaId, 'auto');
    ga('send', 'pageview');
}