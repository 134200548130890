import { useEffect, useState } from 'react';

interface CountdownBannerProps {
    initialTime?: number; // in seconds
    saleText?: string;
}

export const CountdownBanner = ({
    initialTime = 900, // 15 minutes default
    saleText = 'CHRISTMAS SALE'
}: CountdownBannerProps) => {
    const [timeLeft, setTimeLeft] = useState(initialTime);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 0) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    return (
        <div className="countdown-banner">
            <div className="banner-content">
                <div className="countdown-group">
                    <span className="sale-text">{saleText}</span>
                    <span className="is-here">IS HERE!</span>
                    <div className="timer">
                        {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                    </div>
                </div>
                <div className="stock-info-wrapper">
                    <div className="stock-info">
                        <span className="stock-level">
                            <img className="icon" src="/images/stock-low.svg" alt="Low stock" />
                            STOCK LEVEL: <span className="low">LOW</span>
                        </span>
                        <span className="price-tag">
                            <img className="icon" src="/images/low-price.svg" alt="Lowest price ever" />
                            LOWEST PRICE EVER
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}; 