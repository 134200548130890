import { useEffect, useState } from 'react';
import { IQuizItem } from '../../types/quiz';
import { OptionButtons } from '../parts/OptionButtons';
import { QuestionImage } from '../parts/QuestionImage';
import { QuestionText } from '../parts/QuestionText';

type Props = {
  item: IQuizItem;
  onSubmit: (value: string) => void;
  lastQuestion?: boolean;
  answers: Record<string, string | string[]>;
  duration?: number; // Duration in milliseconds (e.g., 5000 for 5 seconds)
};

export const Loader = ({ item, onSubmit, lastQuestion, answers, duration = 5000 }: Props) => {
  const [progress, setProgress] = useState(0);
  const texts = item.loaderTexts;
  const [textIndex, setTextIndex] = useState(0);
  const [text, setText] = useState(texts[textIndex]);

  useEffect(() => {
    if (textIndex < texts.length - 1) {
      const interval = setInterval(() => {
        setTextIndex((prevIndex) => prevIndex + 1);
        setText(texts[textIndex]);
      }, 2000); // Change text every 2 seconds

      return () => clearInterval(interval);
    }
  }, [textIndex, texts.length]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (progress < 100) {
      const increment = 100 / (duration / 50);
      interval = setInterval(() => {
        setProgress((prev) => Math.min(prev + increment, 100));
      }, 50);
    } else if (progress >= 100) {
      setText("Found match!")
      clearInterval(interval);
      onSubmit(null);
    }

    return () => clearInterval(interval);
  }, [progress, duration, onSubmit]);

  return (
    <div className="loader-container">
      <h2 dangerouslySetInnerHTML={{ __html: item.titleHtml }}></h2>
      <p className="analyzing-text">{text}</p>
      <div className="loader-progress-bar">
        <div className="loader-progress" style={{ width: `${progress}%` }}></div>
      </div>
      <div className="before-after-single">
        <img src={item.image} alt="Before and After" />
      </div>
    </div >
  );
};