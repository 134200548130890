import { ReactComponent as Featured1 } from '../../assets/icons/featured_1.svg';
import { ReactComponent as Featured2 } from '../../assets/icons/featured_2.svg';
import { ReactComponent as Featured3 } from '../../assets/icons/featured_3.svg';
import { ReactComponent as Featured4 } from '../../assets/icons/featured_4.svg';
import { ReactComponent as Featured5 } from '../../assets/icons/featured_5.svg';
import { ReactComponent as Featured6 } from '../../assets/icons/featured_6.svg';

export const FeaturedLogoFooter = () => {
  return (
    <div className="featured-logos">
      <div className="container">
        <p className="title">Featured on:</p>
        <div className="logos">
          <div className="logo-wrapper">
            <Featured1/>
          </div>
          <div className="logo-wrapper">
            <Featured2/>
          </div>
          <div className="logo-wrapper">
            <Featured3/>
          </div>
          <div className="logo-wrapper">
            <Featured4/>
          </div>
          <div className="logo-wrapper">
            <Featured5/>
          </div>
          <div className="logo-wrapper">
            <Featured6/>
          </div>
        </div>
      </div>
    </div>
  )
}