import React, { useEffect, useRef, useState, createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { initializeAnalytics } from '../../utils/analytics';
import { brandConfig } from '../../config/brandConfig';

interface AnalyticsContextType {
    isReady: boolean;
}

const AnalyticsContext = createContext<AnalyticsContextType>({ isReady: false });

export const useAnalytics = () => useContext(AnalyticsContext);

interface AnalyticsWrapperProps {
    children: React.ReactNode;
}

export const AnalyticsWrapper: React.FC<AnalyticsWrapperProps> = ({ children }) => {
    const location = useLocation();
    const initialized = useRef(false);
    const [isReady, setIsReady] = useState(false);

    const getBrandFromHostname = () => {
        const hostname = window.location.hostname;
        const parts = hostname.split('.');
        if (parts.length >= 2) {
            const potentialBrand = parts[parts.length - 2];
            // Check if this brand exists in brandConfig
            if (brandConfig[potentialBrand]) {
                return potentialBrand;
            }
        }
        return 'default';
    };

    useEffect(() => {
        if (!initialized.current) {
            const params = new URLSearchParams(location.search);
            const brand = params.get('brand') || getBrandFromHostname();

            initializeAnalytics(brand);

            initialized.current = true;
            setIsReady(true);
        }
    }, [location]);

    return (
        <AnalyticsContext.Provider value={{ isReady }}>
            {children}
        </AnalyticsContext.Provider>
    );
};