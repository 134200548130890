export const WeightLossSvg = ({ className }: { className?: string }) => (
    <svg className={className} width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7566_8421)">
            <mask id="mask0_7566_8421" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x={0} y={0} width={25} height={25}>
                <path d="M0.5 0.892824H24.5V24.8928H0.5V0.892824Z" fill="white" />
            </mask>
            <g mask="url(#mask0_7566_8421)">
                <path d="M12.8338 21.1794H4.67369C3.19713 21.1794 1.97205 20.0377 1.86812 18.5649L0.858672 4.25485C0.743922 2.62768 2.03298 1.24445 3.66419 1.24445H17.5634C19.1946 1.24445 20.4837 2.62768 20.3689 4.25485L19.7594 12.8955" stroke="#000000" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.12858 3.08677H5.09724C4.7055 3.08677 4.3957 3.41855 4.42252 3.8094L4.66781 7.38221C4.69219 7.73691 4.98699 8.01221 5.34253 8.01221H15.8854C16.2409 8.01221 16.5358 7.73691 16.5601 7.38221L16.8054 3.8094C16.8323 3.41855 16.5225 3.08677 16.1307 3.08677H8.00072" stroke="#000000" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.39746 19.2048H12.3301" stroke="#000000" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M22.7498 22.4397C23.622 21.4081 24.1482 20.0745 24.1482 18.6178C24.1482 15.3463 21.4962 12.6943 18.2247 12.6943C14.9533 12.6943 12.3013 15.3463 12.3013 18.6178C12.3013 21.8892 14.9533 24.5413 18.2247 24.5413C19.3693 24.5413 20.4378 24.2163 21.3437 23.6541" stroke="#000000" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.4757 19.7263C20.2188 19.4289 19.7694 19.3961 19.4721 19.653L18.9364 20.1159V15.5668C18.9364 15.1738 18.6178 14.8552 18.2248 14.8552C17.8318 14.8552 17.5132 15.1738 17.5132 15.5668V20.1159L16.9776 19.653C16.6802 19.3961 16.2309 19.4289 15.9739 19.7263C15.717 20.0236 15.7498 20.473 16.0471 20.7299L17.7596 22.2095C18.0228 22.4368 18.4269 22.4373 18.69 22.2095L20.4025 20.7299C20.6999 20.473 20.7326 20.0236 20.4757 19.7263Z" stroke="#000000" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_7566_8421">
                <rect width="24" height="24" fill="white" transform="translate(0.5 0.892822)" />
            </clipPath>
        </defs>
    </svg>

);
