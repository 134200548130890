import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SingleSelect from './QuestionTypes/SingleSelect';
import MultiSelect from './QuestionTypes/MultiSelect';


const Testing: React.FC = () => {

    useEffect(() => {

    })

    return (
        <div>
            <a href="https://cart.lulutox.com/products-order/us/"
                className="top-button">ORDER NOW!</a>
            <br />
            <a href="https://lulutox-cart-staging-e3ec3f7f19b764a4a6.webflow.io/products-order/us"
                className="top-button">ORDER NOW! STAGING</a>

            <br />
            <a href="https://dziugass-stupendous-site.webflow.io/">Another test</a>
            <br />
            <br />
            <a href="https://speed-test.lulutox.com/products-order/us">Speed test production</a>
            <br />
            <a href="https://lulutox-cart-staging-8573f2c79495c87c00.webflow.io/products-order/us">Speed test staging</a>
            <br />

            <br />
            <br />
            <a href="https://speed-test.astrocapfund.com/products-order/us">CLICK THIS</a>
            <br />

        </div>
    );
};

export default Testing;
