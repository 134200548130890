import { Quiz } from "../../data/quizzes";
import { IQuizItem } from "../../types/quiz";


type Props = {
    handleBack: () => void;
    brandLogo: string;
    idx: number;
    quiz: Quiz;
}

export const BannerHeader = ({ handleBack, brandLogo, idx, quiz }: Props) => {
    return (
        <div className="quiz-header-banner-container">
            {idx == 0 && (
                <div className="quiz-header-banner">
                    <div className='banner-header-container'>
                        <p>Hi! This should take less than <b>1 minute.</b></p>
                    </div>
                </div>
            )}
            <img className="logo logo-banner" src={brandLogo} alt="Logo" />
        </div>

    );
};