import { IQuizItem } from '../../types/quiz';

import { ChartQuestion } from './ChartQuestion';
import { EmailQuestion } from './EmailQuestion';
import { InfoQuestion } from './InfoQuestion';
import { Loader } from './Loader';
import { MultipleQuestion } from './MultipleQuestion';
import { SingleQuestion } from './SingleQuestion';

type Props = {
  item: IQuizItem;
  onSubmit: (value?: string | string[], key?: string) => void;
  lastQuestion?: boolean;
  answers: Record<string, string | string[]>;
};

export const Questions = ({ item, onSubmit, lastQuestion, answers }: Props) => {
  const Question = {
    info: InfoQuestion,
    email: EmailQuestion,
    chart: ChartQuestion,
    single: SingleQuestion,
    multiple: MultipleQuestion,
    loader: Loader
  }[item.type];

  return Question ? <Question item={item} onSubmit={onSubmit} lastQuestion={lastQuestion} answers={answers} /> : null;
};
