import { IQuizItem } from '../../types/quiz';

type Props = {
  item: IQuizItem;
  info?: boolean;
  chart?: boolean;
  email?: boolean;
};

export const QuestionImage = ({ item, info, chart, email }: Props) => {
  if (!item?.image) {
    return null;
  }
  if (info) {
    return (
      <img src={item.image} style={{ width: '100%', maxWidth: item.imageWidth || '240px' }} />
    );
  }
  if (chart) {
    return (
      <div>
        <img className="show-on-desktop chart-image" src={item.image} style={{ width: '100%' }} />
        <img className="show-on-mobile" src={item.imageMobile} style={{ width: '100%', maxWidth: '500px' }} />
      </div>

    );
  }
  if (email) {
    return (
      <img src={item.image} style={{ width: '100%', maxWidth: '150px' }} />
    );
  }
  return <img src={item.image} style={{ width: '100%', maxWidth: '220px' }} />;
};
