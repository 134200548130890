import React from 'react';
import { IQuizItem } from '../../types/quiz';

type Props = {
  item: IQuizItem;
  answers: Record<string, string | string[]>;
};

export const QuestionText: React.FC<Props> = ({ item, answers }) => {
  const conditionalText = (text) => {
    // I need to replace in the text {age_group} for example with the value of answers.age_group but this is dynamic so I need to use the key
    // But only if there is something in {} in the text
    if (!text.includes('{')) {
      return text;
    }
    const regex = /{([^}]+)}/g;
    return text.replace(regex, (match, p1) => {
      const answer = answers[p1];
      if (typeof answer === 'object' && answer !== null && 'title' in answer) {
        return answer.title;
      }
      return match;
    });
  }

  return (
    <div className="question-text-container">
      {item.title && <h1 className="question-title q-title">{item.title}</h1>}
      {item?.titleHtml && (
        <h1
          className="question-title q-title"
          dangerouslySetInnerHTML={{ __html: item.titleHtml }}
        />
      )}
      {item.subtitleHtml && (
        <p
          className="question-subtitle-html q-subtitle"
          dangerouslySetInnerHTML={{ __html: conditionalText(item.subtitleHtml) }}
        />
      )}
    </div>
  );
};