import { Quiz } from "../../data/quizzes";
import { IQuizItem } from "../../types/quiz";


type Props = {
    handleBack: () => void;
    brandLogo: string;
    idx: number;
    quiz: Quiz;
}

export const LogoOnlyHeader = ({ handleBack, brandLogo, idx, quiz }: Props) => {
    return (
        <div className="quiz-header-banner-container">
            <img className="logo logo-banner" src={brandLogo} alt="Logo" />
        </div>

    );
};