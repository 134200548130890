// components/QuestionTypes/MultiSelect.tsx
import React from 'react';

interface MultiSelectProps {
    options: string[];
    selectedAnswers: string[];
    onSelectAnswer: (option: string) => void;
}

const MultiSelect: React.FC<MultiSelectProps> = ({ options, selectedAnswers, onSelectAnswer }) => {
    return (
        <div>
            {options.map((option, index) => (
                <div key={index}>
                    <label>
                        <input
                            type="checkbox"
                            value={option}
                            checked={selectedAnswers.includes(option)}
                            onChange={() => onSelectAnswer(option)}
                        />
                        {option}
                    </label>
                </div>
            ))}
        </div>
    );
};

export default MultiSelect;
