import { IQuizItem } from '../types/quiz';
import { Questions } from './questions/Questions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import '../styles/Quiz.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Gtm } from '../apis/gtm';
import { reportToMakeBq } from '../utils/bq';
import { useAnalytics } from './analytics/AnalyticsWrapper';
import { getQuizById, Quiz as QuizType } from '../data/quizzes';
import { brandConfig } from '../config/brandConfig';
import { DefaultHeader } from './headers/DefaultHeader';
import { BannerHeader } from './headers/BannerHeader';
import { DefaultProgressBar } from './progressBars/DefaultProgressBar';
import { BannerProgressBar } from './progressBars/BannerProgressBar';

export const QuizLulutoxTest = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [idx, setIdx] = useState(0);
  const [answers, setAnswer] = useState({});
  const [progress, setProgress] = useState(0);
  const [direction, setDirection] = useState('forward');
  const [quiz, setQuiz] = useState<QuizType | null>(null);
  const quizStartTracked = useRef(false);
  const params = new URLSearchParams(location.search);
  const quizParam = params.get('quiz') || 'lu-test';
  const successUrlParam = params.get('successUrl') || '/';
  const getBrandFromHostname = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    if (parts.length >= 2) {
      const potentialBrand = parts[parts.length - 2];
      if (brandConfig[potentialBrand]) {
        return potentialBrand;
      }
    }
    return 'default';
  };

  const Header = {
    default: DefaultHeader,
    bannerHeader: BannerHeader,
  }[quiz?.headerStyle] ?? DefaultHeader;

  const ProgressBar = {
    default: DefaultProgressBar,
    bannerHeader: BannerProgressBar,
  }[quiz?.progressBarStyle] ?? DefaultProgressBar;

  const brandParam = params.get('brand') || getBrandFromHostname();
  const brandLogo = brandConfig[brandParam]?.logo;

  useEffect(() => {
    const selectedQuiz = getQuizById(quizParam);
    if (selectedQuiz) {
      setQuiz(selectedQuiz);
    } else {
      console.error(`Quiz with id ${quizParam} not found`);
    }
  }, [quizParam]);

  useEffect(() => {
    if (!quizStartTracked.current) {
      Gtm.trackQuizStart(brandParam);

      reportToMakeBq({
        dataset: 'bigquery',
        table: 'quiz_events',
        data: {
          brand: brandParam,
          url: window.location.href,
          event: 'quiz_start',
          auid: params.get('utm_auid'),
        },
      });
      quizStartTracked.current = true;
    }
  }, [params, quiz, brandParam]);

  useEffect(() => {
    if (quiz) {
      setProgress(((idx + 1) / quiz.questions.length) * 100);
    }
  }, [idx, quiz]);

  const question = quiz?.questions[idx] as IQuizItem;

  const onAnswerSubmit = async (v?: string | string[], title?: string | string[]) => {
    if (question.type === 'loader') {
      onFinish(answers);
      return;
    }

    setDirection('forward');
    let value = v;
    if (question.type === 'chart' || question.type === 'info') {
      value = 'clicked_next';
      title = 'clicked_next';
    }

    const newAnswers = {
      ...answers,
      [question.key]: {
        value,
        title: title ?? value,
      },
    };

    setAnswer(newAnswers);

    const event = {
      brand: brandParam,
      step: idx,
      key: question.key,
      url: window.location.href,
      auid: params.get('utm_auid'),
      question: question.title,
      answer: Array.isArray(title) ? title.join(', ') : title,
      answer_key: Array.isArray(value) ? value.join(', ') : value,
      questionType: question.type,
      quizId: quizParam,
    };

    Gtm.trackQuizAnswer(event);
    reportToMakeBq({
      dataset: 'bigquery',
      table: 'quiz_events',
      data: {
        ...event,
        event: 'quiz_answer',
      },
    });

    if (idx === quiz.questions.length - 1) {
      await onFinish(newAnswers);
    } else {
      setIdx(idx + 1);
    }
  };

  const handleBack = () => {
    if (idx === 0) {
      navigate(-1)
    } else {
      setDirection('backward');
      setIdx(idx - 1)
    }
  }

  const onFinish = async (answers: Record<string, { value: string | string[], title: string }>) => {
    const emailAnswer = answers['email'];
    const email = emailAnswer && typeof emailAnswer.value === 'string' ? emailAnswer.value : '';

    Gtm.trackQuizFinish(brandParam, email);

    await reportToMakeBq({
      dataset: 'bigquery',
      table: 'quiz_events',
      data: {
        step: idx,
        questionType: question.type,
        question: question.title,
        key: question.key,
        answer_key: email,
        brand: brandParam,
        answer: email,
        url: window.location.href,
        event: 'quiz_finish',
        auid: params.get('utm_auid'),
        allAnswers: answers,
        email: email,
        quizId: quizParam,
      },
    });

    handleRedirect();
  };

  const handleRedirect = () => {
    const defaultNextUrl = brandConfig[brandParam]?.defaultNextUrl;

    const constructUrlWithParams = (baseUrl: string) => {
      const url = new URL(baseUrl);
      const currentParams = new URLSearchParams(location.search);
      currentParams.forEach((value, key) => {
        url.searchParams.append(key, value);
      });

      return url.toString();
    };

    let targetUrl: string;
    if (!successUrlParam || successUrlParam === '/') {
      targetUrl = constructUrlWithParams(defaultNextUrl);
    } else {
      targetUrl = constructUrlWithParams(successUrlParam);
    }

    setTimeout(() => window.location.href = targetUrl, 1000);
  }

  if (!quiz) {
    return <div>Loading quiz...</div>;
  }

  return (
    <div className={`quiz-container-test ${brandParam}`}>
      <div className="quiz-background"></div>
      <div className="quiz-content">
        <Header handleBack={handleBack} brandLogo={brandLogo} idx={idx} quiz={quiz} />
        {question.type !== 'loader' && (
          <ProgressBar progress={progress} step={question.step} />
        )}
        <div className="question-container">
          <TransitionGroup>
            <CSSTransition
              key={idx}
              timeout={300}
              classNames={`question-${direction}`}
            >
              <div>
                <div className="question-wrapper">
                  <Questions answers={answers} item={question} onSubmit={onAnswerSubmit} lastQuestion={idx === quiz.questions.length - 1} />
                </div>
                {idx > 0 && question.type !== 'loader' && (
                  <div className='back-button-container mt-4'>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.82843 11.3927H20V13.3927H7.82843L13.1924 18.7566L11.7782 20.1708L4 12.3927L11.7782 4.6145L13.1924 6.02871L7.82843 11.3927Z" fill="#02122E" />
                    </svg>
                    <button className="back-button " onClick={() => handleBack()}>
                      Back</button>
                  </div>
                )}
              </div>

            </CSSTransition>
          </TransitionGroup>
        </div>


      </div>
    </div>
  );
};