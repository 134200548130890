import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import QuizOld from './components/QuizOld';
import { Quiz } from './components/Quiz';
import { initializeAnalytics } from './utils/analytics';
import { AnalyticsWrapper } from './components/analytics/AnalyticsWrapper';
import Testing from './components/Testing';
import { QuizLulutoxTest } from './components/QuizLulutoxTest';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Quiz} />
        <Route path="/v1" Component={QuizLulutoxTest} />
        <Route path="/vbf" Component={QuizLulutoxTest} />
        <Route path="/testing" Component={QuizOld} />
        <Route path="/route/test" Component={Testing} />
      </Routes>
    </Router>
  );
};

export default App;
