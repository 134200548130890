import { styled } from 'goober';

import { IQuizItem } from '../../types/quiz';
import { Button } from '../buttons/Button';
import { QuestionImage } from '../parts/QuestionImage';
import { QuestionText } from '../parts/QuestionText';
import { LoaderSvg } from '../../assets/svg/LoaderSvg';
import { useState } from 'react';

type Props = {
  item: IQuizItem;
  onSubmit: (value: string | any) => void;
  lastQuestion?: boolean;
  answers: Record<string, string | string[]>;
};

export const ChartQuestion: React.FC<Props> = ({ item, onSubmit, lastQuestion, answers }) => {
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const submit = () => {
    if (lastQuestion) {
      setLoading(true);
      setButtonDisabled(true);
    }

    onSubmit(item.key);
  }
  return (
    <div className="chart-question-container">
      <QuestionText item={item} answers={answers} />
      <QuestionImage item={item} chart={true} />
      <div style={{ height: '30px' }} />
      <Button
        className="q-cta-button"
        disabled={buttonDisabled}
        onClick={submit}>
        {loading ? <LoaderSvg /> : item.cta || 'Continue'}
      </Button>
    </div>
  );
};