import { IQuizItem } from '../../types/quiz';
import { Button } from '../buttons/Button';
import { QuestionImage } from '../parts/QuestionImage';
import { QuestionLottie } from '../parts/QuestionLottie';
import { QuestionText } from '../parts/QuestionText';

type Props = {
  item: IQuizItem;
  onSubmit: (value: string | any) => void;
  lastQuestion?: boolean;
  answers: Record<string, string | string[]>;
};

export const InfoQuestion: React.FC<Props> = ({ item, onSubmit, lastQuestion, answers }) => {
  return (
    <div className="info-question-container">
      {item.lottie && <QuestionLottie item={item} />}
      <QuestionImage item={item} info={true} />
      <QuestionText item={item} answers={answers} />
      <Button className="q-cta-button mt-4" onClick={onSubmit}>
        {item.cta || 'Continue'}
      </Button>
    </div>
  );
};