import { LoaderSvg } from '../../assets/svg/LoaderSvg';
import { IQuizItem } from '../../types/quiz';
import { validateEmail } from '../../utils/validations';
import { Button } from '../buttons/Button';
import { QuestionImage } from '../parts/QuestionImage';
import { QuestionText } from '../parts/QuestionText';
import { useEffect, useState } from 'react';

type Props = {
  item: IQuizItem;
  onSubmit: (value: string) => void;
  lastQuestion?: boolean;
  answers: Record<string, string | string[]>;
};

export const EmailQuestion = ({ item, onSubmit, lastQuestion, answers }: Props) => {
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [email, setEmail] = useState('');

  const onInput = (e: Event | any) => {
    const value = (e.target as any)?.value;
    if (typeof value === 'string') {
      setEmail(value.trim());
    }
  };

  const submit = () => {
    onSubmit(email);
    setLoading(true);
  };

  const handleKeyPress = (e: KeyboardEvent | any) => {
    if (e.key === 'Enter' && valid) {
      submit();
    }
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => setLoading(false), 3000);
    }
  }, [loading]);

  useEffect(() => {
    setValid(validateEmail(email));
  }, [email]);

  const buttonDisabled = !valid || loading;

  return (
    <div className="email-question-container">
      <QuestionImage item={item} email={true} />
      <QuestionText item={item} answers={answers} />
      <div className="email-input-container q-email-cont">
        {item?.label && (
          <label className="email-label q-email-label" htmlFor="emailInput">
            {item.label}
          </label>
        )}
        <input
          id="emailInput"
          type="email"
          value={email}
          onInput={onInput}
          autoComplete="email"
          className="email-input q-email-input"
          placeholder="Enter your email"
          onKeyDown={handleKeyPress}
        />
      </div>
      <div style={{ height: '30px' }} />
      <Button
        className="q-cta-button"
        disabled={buttonDisabled}
        onClick={submit}>
        {loading ? <LoaderSvg /> : item.cta || 'Continue'}
      </Button>
      <div style={{ height: '30px' }} />
      {item.footnote && (
        <div className="email-footnote-row">
          {/* <img
            style={{ width: '100%', maxWidth: '40px' }}
            src=""
          /> */}
          <span className="email-footnote-note">{item.footnote}</span>
        </div>
      )}
    </div>
  );
};