import { useEffect, useState } from 'react';
import { IQuizItem } from '../../types/quiz';
import { ReactComponent as FiveStars } from '../../assets/icons/5_stars.svg';

type Props = {
  item: IQuizItem;
  onSubmit: (value: string) => void;
  lastQuestion?: boolean;
  answers: Record<string, string | string[]>;
  duration?: number;
};

export const ReviewLoader = ({ item, onSubmit, duration = 5000 }: Props) => {
  const [progress, setProgress] = useState(0);
  const texts = item.loaderTexts;
  const [textIndex, setTextIndex] = useState(0);
  const [text, setText] = useState(texts[textIndex]);

  useEffect(() => {
    if (textIndex < texts.length - 1) {
      const interval = setInterval(() => {
        setTextIndex((prevIndex) => prevIndex + 1);
        setText(texts[textIndex]);
      }, 2000); // Change text every 2 seconds

      return () => clearInterval(interval);
    }
  }, [textIndex, texts.length]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (progress < 100) {
      const increment = 100 / (duration / 50);
      interval = setInterval(() => {
        setProgress((prev) => Math.min(prev + increment, 100));
      }, 50);
    } else if (progress >= 100) {
      setText('Found match!');
      clearInterval(interval);
      onSubmit(null);
    }

    return () => clearInterval(interval);
  }, [progress, duration, onSubmit]);

  return (
    <div className="loader-container review">
      <h2 dangerouslySetInnerHTML={{ __html: item.titleHtml }}></h2>
      <p className="analyzing-text">{text}</p>
      <div className="loader-progress-bar">
        <div
          className="loader-progress"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className="review-loader">
        <div className="review-loader__profile">
          <div className="review-loader__image-wrapper">
            <img
              height={48}
              width={48}
              src="/images/loader_profile.png"
              alt="Amanda L."
            />
            <p className="name">Amanda L.</p>
          </div>
          <FiveStars />
        </div>
        <p className="review-text">
          I kept seeing ads for Lulutox tea, so when I saw discount and 30-day
          money back guarantee so I decided to try! After about a week of
          drinking it 2 times a day (I know it says 1 time but I really love the
          taste :D ), I can gladly say that I feel AMAZING!!! no more bloating
          and my skin improved!
        </p>
        <div className="review-image">
          <div className="image-wrapper">
            <img src="/images/review_image_1.png" />
          </div>
          <div className="image-wrapper">
            <img src="/images/review_image_2.jpg" />
          </div>
        </div>
      </div>
    </div>
  );
};
