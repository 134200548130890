// components/QuestionTypes/SingleSelect.tsx
import React from 'react';

interface SingleSelectProps {
    options: string[];
    selectedAnswer: string[];
    onSelectAnswer: (option: string) => void;
}

const SingleSelect: React.FC<SingleSelectProps> = ({ options, selectedAnswer, onSelectAnswer }) => {
    return (
        <div>
            {options.map((option, index) => (
                <div key={index}>
                    <label>
                        <input
                            type="radio"
                            name="single-select"
                            value={option}
                            checked={selectedAnswer.includes(option)}
                            onChange={() => onSelectAnswer(option)}
                        />
                        {option}
                    </label>
                </div>
            ))}
        </div>
    );
};

export default SingleSelect;
