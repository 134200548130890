import { IQuizItem } from '../../types/quiz';
import { GenderOption } from '../parts/GenderOption';
import { QuestionImage } from '../parts/QuestionImage';
import { QuestionText } from '../parts/QuestionText';

type Props = {
  item: IQuizItem;
  onSubmit: (value?: string | string[], key?: string) => void;
  lastQuestion?: boolean;
  answers: Record<string, string | string[]>;
};


export const GenderQuestion = ({ item, onSubmit, lastQuestion, answers }: Props) => {
  return (
    <div className="single-question-container">
      {item?.topReviewImage && (
        <img height="26px" src={item.topReviewImage} alt="top review" />
      )}
      {item?.topTitle && (
        <span className="single-question-top-title q-top-title">{item.topTitle}</span>
      )}
      {item?.topSubtitle && (
        <span className="single-question-top-subtitle q-top-title">{item.topSubtitle}</span>
      )}

      <div className="gender-options-wrapper">
        <div className="gender-option-column">
          <div className="gender-option-item">
            <img
              src="/images/male.png"
              alt="Male portrait"
              className="gender-portrait"
              onClick={() => onSubmit('male', 'gender')}
            />
            <GenderOption
              label="Male"
              emoji="👨"
              onClick={() => onSubmit('male', 'gender')}
            />
          </div>
        </div>
        <div className="gender-option-column">
          <div className="gender-option-item">
            <img
              src="/images/female.png"
              alt="Female portrait"
              className="gender-portrait"
              onClick={() => onSubmit('female', 'gender')}
            />
            <GenderOption
              label="Female"
              emoji="👩"
              onClick={() => onSubmit('female', 'gender')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};