import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import QuizOld from './components/QuizOld';
import { Quiz } from './components/Quiz';
import Testing from './components/Testing';
import { QuizLulutoxTest } from './components/QuizLulutoxTest';
import { QuizLulutoxTestGender } from './components/QuizLulutoxTestGender';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Quiz} />
        <Route path="/v1" Component={QuizLulutoxTest} />
        <Route path="/tt" element={<QuizLulutoxTest questionsId="lu-tt" nextUrl="http://getlulutox.com/products/product-tt" />} />
        <Route path="/vbf" element={<QuizLulutoxTest nextUrl="https://shop.lulutox.com/products/product-1-s" appendAnswersToUrl={true} questionsId='lu-test-summary' />} />
        <Route path="/vbf-2" element={<QuizLulutoxTestGender nextUrl="https://shop.lulutox.com/products/product-1-s" appendAnswersToUrl={true} questionsId='lu-test-summary-2' />} />

        <Route path="/vbf-old" element={<QuizLulutoxTest nextUrl="https://lulutox.com/products/product-qzbf75" />} />

        <Route path="/vbf-w" element={<QuizLulutoxTest nextUrl="https://wellaray.com/products/product-1/" questionsId='wellaray-main' />} />
      </Routes>
    </Router>
  );
};

export default App;
