import { IImportLeadPayload, PaySource } from '../types/api';
import { GaEvent } from '../types/ga';

export type GaPaymentButtonType =
  | string // paypal_{mid} | paypal_reach_{mid} | paypal_express_{mid}
  | 'klarna'
  | 'paypal'
  | 'credit_card'
  | 'apple_pay'
  | 'google_pay'
  | 'klarna_stripe';

export interface IMakeError {
  url?: string;
  email?: string;
  message: string;
  orderId?: string;
  sentryEventId?: string;
  error_message?: string;
}

interface IQuizAnswer {
  brand: string;
  step: number;
  key: string;
  answer: string | string[];
  question?: string;
  questionType: string;
}

export class Gtm {
  private static logEvent = (event: GaEvent) => {
    if (window?.dataLayer) {
      window.dataLayer.push(event);
    }
  };

  static trackErrorEvent = (error: IMakeError) => {
    Gtm.logEvent({
      event: 'fe_error',
      ...error,
    });
  };

  static trackBuyButtonClick = (
    email: string,
    orderId: string,
    buttonType: GaPaymentButtonType,
  ) => {
    Gtm.logEvent({
      event: 'payment_button_click',
      user_id: email,
      transaction_id: orderId,
      payment_type: buttonType,
    });
  };

  static trackPageView = (category: string, productName: string) => {
    Gtm.logEvent({
      event: 'eec.checkoutStep',
      ecommerce: {
        checkout: {
          category,
          PageViewProduct: productName,
        },
      },
    });
  };

  static trackQuizAnswer = (answer: IQuizAnswer) => {
    Gtm.logEvent({
      event: 'quiz_answer',
      brand: answer.brand,
      question: {
        key: answer.key,
        brand: answer.brand,
        answer: answer.answer,
        question: answer.question,
        question_type: answer.questionType,
        step: answer.step,
      },
    });
  };

  static trackQuizStart = (brand: string) => {
    Gtm.logEvent({
      event: 'quiz_start',
      brand,
    });
  };

  static trackQuizFinish = (brand: string, email: string) => {
    Gtm.logEvent({
      event: 'quiz_finish',
      brand,
      user_id: email,
    });
  };

  static trackEmailPopupOpen = (brand: string) => {
    Gtm.logEvent({
      event: 'email_popup_open',
      brand,
    });
  };

  static trackTrailPopup = (brand: string, status: string) => {
    Gtm.logEvent({
      event: `trial_popup_${status}`,
      brand,
    });
  };

  static trackTrailPopupClick = (brand: string) => {
    Gtm.logEvent({
      event: `trial_popup_click`,
      brand,
    });
  };

  static trackPopupEmailSubmit = (brand: string, email: string) => {
    Gtm.logEvent({
      event: 'email_popup_submit',
      brand,
      user_id: email,
    });
  };

  static trackEmailPopupClose = (brand: string, email?: string) => {
    Gtm.logEvent({
      event: 'email_popup_closed',
      brand,
      user_id: email,
    });
  };

  static trackEmail = (email: string, client: string) => {
    Gtm.logEvent({
      event: 'Email_Event',
      brand: client,
      user_id: email,
    });
  };

  static trackUpsellButtonClick = (
    paymentType: PaySource,
    orderId?: string,
    email?: string,
  ) => {
    Gtm.logEvent({
      event: `upsell_payment_button_click`,
      user_id: email,
      transaction_id: orderId,
      payment_type: paymentType,
    });
  };
}
