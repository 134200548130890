export const ArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="12"
    viewBox="0 0 20 12"
    fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9613 0.534677L19.7408 5.45363C20.0341 5.75548 20.0341 6.24489 19.7408 6.54673L14.9613 11.4657C14.668 11.7676 14.1924 11.7676 13.8992 11.4657C13.6059 11.1638 13.6059 10.6744 13.8992 10.3726L17.3966 6.77312L0 6.77312L0 5.22725L17.3966 5.22725L13.8992 1.62777C13.6059 1.32592 13.6059 0.836528 13.8992 0.534677C14.1924 0.232826 14.668 0.232826 14.9613 0.534677Z"
    />
  </svg>
);
