export const BloatingSvg = ({ className }: { className?: string }) => (
    <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7566_8478)">
            <path d="M15.7343 14.2991H4.7187C2.65625 14.2991 0.96875 12.6116 0.96875 10.5491C0.96875 8.56701 2.5273 6.93154 4.47936 6.80699M8.69806 6.79907H13.8593M4.7187 10.5491H17.8437C19.9062 10.5491 21.5937 12.2366 21.5937 14.299C21.5937 14.5643 21.5655 14.8234 21.5125 15.0735M15.6505 18.049H8.46875" stroke="black" strokeMiterlimit="2.6131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.8125 23.2051C22.1425 23.2051 24.0312 21.3163 24.0312 18.9863C24.0312 16.6564 22.1425 14.7676 19.8125 14.7676C17.4825 14.7676 15.5938 16.6564 15.5938 18.9863C15.5938 21.3163 17.4825 23.2051 19.8125 23.2051Z" stroke="black" strokeMiterlimit="2.6131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.8154 20.2046H21.8158" stroke="black" strokeMiterlimit="2.6131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.6455 18.1592H21.6459" stroke="black" strokeMiterlimit="2.6131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.561 20.8315H19.5614" stroke="black" strokeMiterlimit="2.6131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.7393 19.0651C19.2563 19.0365 19.6522 18.5942 19.6236 18.0772C19.5951 17.5602 19.1528 17.1643 18.6358 17.1929C18.1188 17.2215 17.7229 17.6637 17.7515 18.1807C17.78 18.6977 18.2223 19.0936 18.7393 19.0651Z" stroke="black" strokeMiterlimit="2.6131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.06119 14.2988C5.51492 14.2995 4.25 15.5649 4.25 17.1113C4.25 18.6582 5.51563 19.9238 7.0625 19.9238C8.03 19.9238 8.82158 20.7155 8.82158 21.6829V23.2051" stroke="black" strokeMiterlimit="2.6131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.228 18.0488C11.1955 18.0489 11.987 18.8405 11.987 19.8079V23.2051" stroke="black" strokeMiterlimit="2.6131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.2656 10.549C16.8124 10.549 18.0779 9.2834 18.0779 7.73653C18.0779 6.18965 16.8123 4.92403 15.2654 4.92403C14.2979 4.92403 13.5063 4.13235 13.5063 3.16495V2.58032" stroke="black" strokeMiterlimit="2.6131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.1002 6.79903C11.1328 6.79898 10.3413 6.00735 10.3413 5.03995V2.58032" stroke="black" strokeMiterlimit="2.6131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.58838 6.79907H6.58871" stroke="black" strokeMiterlimit="2.6131" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_7566_8478">
                <rect width="24" height="24" fill="white" transform="translate(0.5 0.892822)" />
            </clipPath>
        </defs>
    </svg>

);
