import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SingleSelect from './QuestionTypes/SingleSelect';
import MultiSelect from './QuestionTypes/MultiSelect';

interface Question {
    type: 'single' | 'multi';
    question: string;
    key: string;
    options: string[];
    answer: string[];
}

// Example quizzes, these would be fetched dynamically based on `quiz` parameter
const quizzes: { [key: string]: Question[] } = {
    'first-quiz': [
        {
            type: 'single',
            question: 'What is the capital of France?',
            key: 'capital',
            options: ['Berlin', 'Madrid', 'Paris', 'Rome'],
            answer: ['Paris'],
        },
        {
            type: 'multi',
            question: 'Which of the following are programming languages?',
            key: 'languages',
            options: ['Python', 'HTML', 'CSS', 'JavaScript'],
            answer: ['Python', 'JavaScript'],
        },
    ],
    'second-quiz': [
        {
            type: 'single',
            question: 'What is the capital of Italy?',
            key: 'capital',
            options: ['Berlin', 'Rome', 'Paris', 'Madrid'],
            answer: ['Rome'],
        },
        {
            type: 'multi',
            question: 'Which of the following are frontend languages?',
            key: 'frontend',
            options: ['JavaScript', 'Python', 'CSS', 'HTML'],
            answer: ['JavaScript', 'CSS', 'HTML'],
        },
    ],
};

const QuizOld: React.FC = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState<{ [key: string]: string[] }>({});
    const [score, setScore] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const quizParam = params.get('quiz') || 'first-quiz';
    const brandParam = params.get('brand') || 'default';

    // Fetch the correct quiz based on the quiz parameter
    const questions = quizzes[quizParam] || quizzes['first-quiz'];
    const logoMap: { [key: string]: string } = {
        wellnee: 'https://trywellnee.com/index_files/logo.svg',
        bandoo: 'https://trybandoo.com/index_files/logo.svg',
    };

    // Dynamically set the logo based on the brand parameter
    const logoSrc = logoMap[brandParam] || logoMap['default'];

    const handleSingleSelect = (option: string) => {
        const question = questions[currentQuestion];
        setSelectedAnswers({
            ...selectedAnswers,
            [question.key]: [option],
        });
    };

    const handleMultiSelect = (option: string) => {
        const question = questions[currentQuestion];
        const previousAnswers = selectedAnswers[question.key] || [];
        const newAnswers = previousAnswers.includes(option)
            ? previousAnswers.filter((ans) => ans !== option)
            : [...previousAnswers, option];

        setSelectedAnswers({
            ...selectedAnswers,
            [question.key]: newAnswers,
        });
    };

    const handleNextQuestion = () => {
        const correctAnswers = questions[currentQuestion].answer;
        const userAnswers = selectedAnswers[currentQuestion] || [];

        if (correctAnswers.length === userAnswers.length && correctAnswers.every((ans) => userAnswers.includes(ans))) {
            setScore(score + 1);
        }

        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < questions.length) {
            setCurrentQuestion(nextQuestion);
        } else {
            navigate('/result', { state: { score } });
        }
    };

    return (
        <div className={`quiz-container ${brandParam}`}>
            <header className="quiz-header">
                <img src={logoSrc} alt="Logo" className="logo" />
            </header>

            <h2>{questions[currentQuestion].question}</h2>
            {questions[currentQuestion].type === 'single' ? (
                <SingleSelect
                    options={questions[currentQuestion].options}
                    selectedAnswer={selectedAnswers[questions[currentQuestion].key] || []}
                    onSelectAnswer={handleSingleSelect}
                />
            ) : (
                <MultiSelect
                    options={questions[currentQuestion].options}
                    selectedAnswers={selectedAnswers[questions[currentQuestion].key] || []}
                    onSelectAnswer={handleMultiSelect}
                />
            )}
            <button onClick={handleNextQuestion}>Next</button>
        </div>
    );
};

export default QuizOld;
