import { IQuizItem } from '../../types/quiz';
import { OptionButtons } from '../parts/OptionButtons';
import { QuestionImage } from '../parts/QuestionImage';
import { QuestionText } from '../parts/QuestionText';

type Props = {
  item: IQuizItem;
  onSubmit: (value: string) => void;
  lastQuestion?: boolean;
  answers: Record<string, string | string[]>;
};

export const SingleQuestion = ({ item, onSubmit, lastQuestion, answers }: Props) => {
  return (
    <div className="single-question-container">
      {item?.topReviewImage && (
        <img height="26px" src={item.topReviewImage} alt="top review" />
      )}
      {item?.topTitle && (
        <span className="single-question-top-title q-top-title">{item.topTitle}</span>
      )}
      {item?.topSubtitle && (
        <span className="single-question-top-subtitle q-top-title">{item.topSubtitle}</span>
      )}
      <QuestionImage item={item} />
      <QuestionText item={item} answers={answers} />
      <OptionButtons item={item} onSubmit={onSubmit} />
    </div>
  );
};