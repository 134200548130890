import { IQuizItem } from '../types/quiz';

export interface Quiz {
    id: string;
    headerStyle?: string;
    progressBarStyle?: string;
    questions: IQuizItem[];
}

export const quizzes: Quiz[] = [
    {
        id: 'lu-main',
        questions: [
            {
                key: "goal",
                type: "single",
                topTitle: "Lighter, Energized, Glowing - One Cup At a Time",
                topSubtitle: "Lulutox tea consists of 12 well-known, delicious ingredients that can help you achieve various health goals.",
                topReviewImage: "/images/reviews-lulutox.png",
                title: "My goal is:",
                options: [
                    {
                        title: "Manage weight",
                        key: "manage_weight"
                    },
                    {
                        title: "Detox my body",
                        key: "detox_body"
                    },
                    {
                        title: "Relieve menopause symptoms",
                        key: "menopause_symptoms"
                    },
                    {
                        title: "Relieve bloating/constipation",
                        key: "bloating_constipation"
                    },
                ],
                optionIcon: "arrow"
            },
            {
                key: "symptoms",
                type: "single",
                title: "Do you experience any of the following symptoms?",
                options: [
                    {
                        title: "Unwanted weight",
                        key: "unwanted_weight"
                    },
                    {
                        title: "Bloating",
                        key: "bloating"
                    },
                    {
                        title: "Sleep difficulties",
                        key: "sleep_difficulties"
                    },
                    {
                        title: "Low energy",
                        key: "low_energy"
                    },
                    {
                        title: "None",
                        key: "none"
                    }
                ],
                optionIcon: "arrow"
            },
            {
                key: "gained_weight_last_year",
                type: "single",
                title: "Have you gained weight in the last year?",
                options: [
                    {
                        title: "Yes",
                        key: "yes"
                    },
                    {
                        title: "I'm not sure",
                        key: "not_sure"
                    },
                    {
                        title: "No",
                        key: "no"
                    }
                ],
                optionIcon: "arrow"
            },
            {
                key: "change_notice_time",
                type: "single",
                title: "When do you expect to see your first results after using Lulutox?",
                optionIcon: "arrow",
                options: [
                    {
                        title: "Withing a week",
                        key: "within_a_week"
                    },
                    {
                        title: "Within a month",
                        key: "within_a_month"
                    },
                    {
                        title: "Within 2-3 months",
                        key: "within_two_three_months"
                    },
                    {
                        title: "I don't have any expectations",
                        key: "no_expectations"
                    }
                ]
            },
            {
                key: "health_improvement_offer",
                type: "info",
                title: "We can help you improve your health!",
                subtitleHtml: "<b>Most users</b> noticed positive effects from Lulutox within a week.",
                optionIcon: "arrow",
                image: "https://cdn.prod.website-files.com/660d1017ba78506b9fd36e6f/66e7f28cca69d1bf7d4e6343_Quiz%20Br.png"
            },
            {
                key: "age_group",
                type: "single",
                title: "To help us further personalize your offer, please tell us which age group you are in.",
                optionIcon: "arrow",
                options: [
                    {
                        title: "18-24",
                        key: "18-24"
                    },
                    {
                        title: "25-34",
                        key: "25-34"
                    },
                    {
                        title: "35-44",
                        key: "35-44"
                    },
                    {
                        title: "45-54",
                        key: "45-54"
                    },
                    {
                        title: "55-64",
                        key: "55-64"
                    },
                    {
                        title: "65+",
                        key: "65+"
                    }
                ]
            },
            {
                key: "weight_reduction_stat",
                type: "chart",
                image: "/images/lulutox-testimonial-desktop.png",
                imageMobile: "/images/lulutox-testimonial-mobile.png",
                titleHtml: '<span style="color:#e4469d;">94%</span> of users experienced weight reduction within the first month.',
                cta: "Get my results"
            },
            {
                key: "email",
                type: "email",
                label: "Enter your email",
                title: "Enter your email to receive your quiz results and discover the ideal solution for improving your health:",
                cta: "Get my results"
            },
        ]
    },
    {
        id: 'we-main',
        questions: [
            {
                "key": "gender",
                "optionIcon": "arrow",
                "options": [
                    {
                        title: "Male",
                        key: "male"
                    },
                    {
                        title: "Female",
                        key: "female"
                    },
                    {
                        title: "Other",
                        key: "other"
                    },
                ],
                "title": "To begin choose your gender:",
                "topTitle": "Answer a couple of brief questions to share your experiences, and we’ll uncover how knee patches might bring comfort to your daily routine.",
                "type": "single"
            },
            {
                "key": "knee_pain",
                "optionIcon": "arrow",
                "options": [
                    {
                        title: "Yes",
                        key: "yes"
                    },
                    {
                        title: "No",
                        key: "no"
                    }
                ],
                "title": "Do you experience any type of knee pain (or pain in general):",
                "type": "single"
            },
            {
                "key": "pain_often",
                "optionIcon": "arrow",
                "options": [
                    {
                        title: "Daily",
                        key: "daily"
                    },
                    {
                        title: "Weekly",
                        key: "weekly"
                    },
                    {
                        title: "Monthly",
                        key: "monthly"
                    },
                    {
                        title: "Rarely",
                        key: "rarely"
                    }
                ],
                "title": "How often do you feel this pain?",
                "type": "single"
            },
            {
                "key": "how_long",
                "optionIcon": "arrow",
                "options": [
                    {
                        title: "Less than 3 months",
                        key: "less_than_3_months"
                    },
                    {
                        title: "3-6 months",
                        key: "3_6_months"
                    },
                    {
                        title: "6-12 months",
                        key: "6_12_months"
                    },
                    {
                        title: "More than 1 year",
                        key: "more_than_1_year"
                    }
                ],
                "title": "How long have you been dealing with joint or muscle pain?",
                "type": "single"
            },
            {
                "key": "chart_1",
                "type": "chart",
                "image": "/images/chart-wellnee.png",
                "imageMobile": "/images/chart-wellnee.png",
                "titleHtml": "<span style=\"color:#00C249;\">82%</span> of our customers report faster results with Wellnee",
                "cta": "Continue"
            },
            {
                "key": "products_tried",
                "optionIcon": "arrow",
                "options": [
                    {
                        title: "Over-the-counter pain medications",
                        key: "over_the_counter_pain_medications"
                    },
                    {
                        title: "Physical therapy or exercises",
                        key: "physical_therapy_or_exercises"
                    },
                    {
                        title: "Other",
                        key: "other"
                    },
                    {
                        title: "Have not tried any pain relief methods",
                        key: "have_not_tried_any_pain_relief_methods"
                    },
                    {
                        title: "Topical pain relief creams or gels",
                        key: "topical_pain_relief_creams_or_gels"
                    },
                    {
                        title: "Knee braces or supports",
                        key: "knee_braces_or_supports"
                    }
                ],
                "title": "Have you previously tried any of the following knee pain relief products or treatments?",
                "type": "single",
            },
            {
                "key": "looking_for_solution",
                "type": "single",
                "title": "Are you looking for a long-term solution or immediate relief for your pain?",
                "optionIcon": "arrow",
                "options": [
                    {
                        title: "Long term - I want to relieve knee pain for good",
                        key: "long_term_solution"
                    },
                    {
                        title: "Short term - I want to end knee pain as soon as possible",
                        key: "immediate_relief"
                    }
                ]
            },
            {
                "key": "age",
                "optionIcon": "arrow",
                "options": [
                    {
                        title: "Up to 30",
                        key: "up_to_30"
                    },
                    {
                        title: "30 - 45",
                        key: "30_45"
                    },
                    {
                        title: "46 - 60",
                        key: "46_60"
                    },
                    {
                        title: "61+",
                        key: "61+"
                    }
                ],
                "title": "What is your age?",
                "type": "single"
            },
            {
                "key": "email",
                "type": "email",
                "label": "Enter your email",
                "title": "Enter your email to receive your quiz results and discover the ideal pain relief solution:",
                "cta": "Get my results"
            }
        ]

    },
    {
        id: 'lu-test',
        headerStyle: "bannerHeader",
        progressBarStyle: "bannerHeader",
        questions: [
            {
                key: "goal",
                type: "multiple",
                title: "Select the areas you'd like to improve",
                cta: "Next",
                step: 0,
                options: [
                    {
                        icon: "weightLoss",
                        titleHtml: "<b>Weight:</b> Loss & management",
                        title: "Weight: Loss & management",
                        key: "weight_loss_management"
                    },
                    {
                        icon: "immunity",
                        titleHtml: "<b>Immunity:</b> Boost & improvement",
                        title: "Immunity: Boost & improvement",
                        key: "immunity_boost_improvement"
                    },
                    {
                        icon: "bloating",
                        titleHtml: "<b>Bloating:</b> Reduction & management",
                        title: "Bloating: Reduction & management",
                        key: "bloating_reduction_management"
                    },
                    {
                        icon: "energy",
                        titleHtml: "<b>Energy:</b> Increase & stability",
                        title: "Energy: Increase & stability",
                        key: "energy_increase_stability"
                    },
                ],
                optionIcon: "checkbox",
                optionSelectedIcon: 'checkbox-selected'
            },
            {
                key: "suffer_from",
                type: "multiple",
                title: "Do you suffer from any of the following:",
                cta: "Next",
                step: 0,
                options: [
                    {
                        title: "Trouble losing weight",
                        key: "trouble_losing_weight"
                    },
                    {
                        title: "Bloating or digestion issues",
                        key: "bloating"
                    },
                    {
                        title: "Low energy levels",
                        key: "low_energy_levels"
                    },
                    {
                        title: "Feeling under the weather",
                        key: "feeling_under_the_weather"
                    },
                    {
                        title: "None",
                        key: "none",
                        skip: true
                    }
                ],
                optionIcon: "arrow"
            },
            {
                key: "gained_weight_last_year",
                type: "single",
                title: "Have you experienced unwanted weight gain in the past year?",
                step: 0,
                options: [
                    {
                        title: "Yes, a lot",
                        key: "yes_a_lot"
                    },
                    {
                        title: "Yes, a little bit",
                        key: "yes_a_little_bit"
                    },
                    {
                        title: "I'm not sure",
                        key: "not_sure"
                    },
                    {
                        title: "No, I haven't",
                        key: "no_i_havent"
                    },
                    {
                        title: "No, I've actually lost weight",
                        key: "no_i_ve_lost_weight"
                    }
                ],
                optionIcon: "arrow"
            },
            {
                key: "based_on_answers_1",
                step: 1,
                type: "info",
                title: "Based on your answers, we can help!",
                subtitleHtml: "<b>9 out of 10 users</b> saw a reduction in weight and improvement in wellbeing within the first month.",
                optionIcon: "arrow",
                lottie: "/animations/lottie/heart.json",
                cta: "Next"
            },
            {
                key: "bloating_bothers",
                step: 1,
                type: "multiple",
                title: "What bothers you the most about bloating?",
                optionIcon: "arrow",
                cta: "Next",
                options: [
                    {
                        title: "Painful pressure",
                        key: "painful_pressure"
                    },
                    {
                        title: "Self-conscious due to appearance",
                        key: "self_conscious_due_to_appearance"
                    },
                    {
                        title: "Accumulating gas",
                        key: "accumulating_gas"
                    },
                    {
                        title: "None",
                        key: "none",
                        skip: true
                    }
                ]
            },
            {
                key: "age_group",
                type: "single",
                step: 1,
                title: "How can we tailor our recommendations to suit your age group?",
                optionIcon: "arrow",
                options: [
                    {
                        title: "18-24",
                        key: "18-24"
                    },
                    {
                        title: "25-34",
                        key: "25-34"
                    },
                    {
                        title: "35-44",
                        key: "35-44"
                    },
                    {
                        title: "45-54",
                        key: "45-54"
                    },
                    {
                        title: "55+",
                        key: "55+"
                    },
                ]
            },
            {
                key: "based_on_answers_2",
                type: "info",
                step: 2,
                title: "Amazing news!",
                subtitleHtml: "Women aged <b>{age_group}</b> with similar concerns had a <b>93% success rate</b> with Lulutox!",
                optionIcon: "arrow",
                lottie: "/animations/lottie/heart.json",
                cta: "Next"
            },
            {
                key: "energy_levels",
                step: 2,
                type: "multiple",
                title: "Are you concerned about your energy levels?",
                optionIcon: "arrow",
                cta: "Next",
                options: [
                    {
                        title: "Yes, I'm constantly tired",
                        key: "yes_im_constantly_tired"
                    },
                    {
                        title: "Sometimes I get post-lunch slump",
                        key: "sometimes_i_get_post_lunch_slump"
                    },
                    {
                        title: "No, I have loads of energy",
                        key: "no_i_have_loads_of_energy"
                    },
                    {
                        title: "I don't know",
                        key: "dont_know",
                        skip: true
                    }
                ]
            },
            {
                key: "immune_system",
                type: "single",
                step: 2,
                title: "Would you say you have a strong immune system?",
                optionIcon: "arrow",
                options: [
                    {
                        title: "No, I'm constantly sick",
                        key: "no_im_constantly_sick"
                    },
                    {
                        title: "No, not really",
                        key: "no_not_really"
                    },
                    {
                        title: "Not sure, but concerned about it",
                        key: "not_sure_but_concerned"
                    },
                    {
                        title: "Yes, I feel great",
                        key: "yes_i_feel_great"
                    },
                    {
                        title: "Yes, but I'm interested in boosting my immunity",
                        key: "yes_but_interested_in_boosting_immunity"
                    }
                ]
            },
            {
                key: "tea_blend",
                type: "single",
                step: 3,
                title: "Have you ever tried a detoxifying, energizing tea blend?",
                optionIcon: "arrow",
                options: [
                    {
                        title: "Yes, in the past",
                        key: "yes_in_the_past"
                    },
                    {
                        title: "Yes, I have it now",
                        key: "yes_i_have_it_now"
                    },
                    {
                        title: "No",
                        key: "no"
                    }
                ]
            },
            {
                key: "digestive_issues",
                type: "multiple",
                step: 3,
                title: "Do you experience any of these digestive issues?",
                optionIcon: "arrow",
                cta: "Next",
                options: [
                    {
                        title: "Indigestion",
                        key: "indigestion"
                    },
                    {
                        title: "Constipation",
                        key: "constipation"
                    },
                    {
                        title: "Irregularity",
                        key: "irregularity"
                    },
                    {
                        title: "Food intolerances",
                        key: "food_intolerances"
                    },
                    {
                        title: "None",
                        key: "none",
                        skip: true
                    }
                ]
            },
            {
                key: "loader",
                type: "loader",
                step: 3,
                titleHtml: "<b style='color:#E4469D;'>93%</b> of users experienced weight reduction within the first month.",
                image: "/images/lulutox-loader-before-after.png",
                loaderTexts: ["Analyzing your answers..."],
            },

        ]
    },
];

export function getQuizById(id: string): Quiz | undefined {
    return quizzes.find(quiz => quiz.id === id);
}